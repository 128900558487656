import React, { useEffect, useState, Fragment, useRef, Suspense } from "react";
import { useHistory } from "react-router";
import DataTable from "../../../components/Datatable/Datatable";
import CreateJob from "../../../components/CreateJob/CreateJob";
import DatableActionList from "../../../components/Datatable/DatatableActionButton";
import CreateionDateTimeForJobList from "./CreateionDateTimeForJobList";
import ResourceDetailsInJobTable from "./ResourceDetailsInJobTable";
import "./JobsList.scss";
import { jobStatusData } from "../../../constants/jobStatusData";
import AlertDialog from "../../../components/Dialog/Dialog";
import TaskDetail from "../../../components/TaskDetail/TaskDetail";
import { useAppContext } from "../../../AppContext/App.context";
import CommonEndpoints from "../../../services/commonApi.service";
import useApiService from "../../../services/api.service";
import useLoader from "../../../hooks/useLoader";
import useToast from "../../../components/Toast/hooks/useToast";
import useLocalStorage from "../../../hooks/localStorage";
import ReassignJob from "../../../components/ReassignJob/ReassignJob";
import headerDropdownService from "../../../services/headerDropdown.service";
import { ImportExportService } from "../../../components/ImportExport/ImportExport.endpoints";
import { downloadDataAsPDF } from "../../../utils/exportCsv";
import JobListFilter from "./JobListFilter";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Badge from "./Badge";
import CancelIcon from "@material-ui/icons/Cancel";
import PageHeading from "../../../components/PageHeading/PageHeading";
import DateTimeFormatter, {
  convertDateTimeFormatToTimeDateString,
} from "../../../utils/DateFormatter";
import { RolePermissions } from "../../../enum/RolePermissions";
import useLocaStorage from "../../../hooks/localStorage";
import ShowTaskDetailsInHtmlView from "../../../components/ShowTaskDetail/ShowTaskDetailsInHtmlView";
import Import from "../../../components/ImportExport/Import";
import filterColumn from "../../../assets/images/Icon_feather-filter.svg";
import { useTranslation } from "react-i18next";
import { JobColumnList } from "../../../constants/jobListColumns";
import JobColumnFilter from "./jobColumnFilter";
import ExportJobs from "../../../components/ImportExport/ExportJobs";
import JobListCount from "./JobListCount";
import Tooltip from "../../../components/Tooltip/Tooltip";
import FilterIcon from "../../../assets/images/Icon_feather-filter.png";
import ListColumnIcon from "../../../assets/images/list_column_filter.png";
import ExpandIcon from "../../../assets/images/Expand.png";
import CollapseIcon from "../../../assets/images/Collapse.png";
import SearchIcon from "../../../assets/images/search_icon.png";
import Export from "../../../assets/images/Export.svg";
import Delete from "../../../assets/images/Delete.svg";
import { Tooltip as ToolTipMaterial } from "@material-ui/core";
import ReassignIcon from "../../../assets/images/ReassignIcon.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import { Value } from "@material-ui/lab/useAutocomplete";
import { themeColor } from "../../../constants/themeColor";
import crossIcon from "../../../assets/images/crossIcon.svg";
import { Button, Checkbox } from "@material-ui/core";
import JobListEndpoints from "./JobList.endpoints";
import { templateApiService } from "../../../services/ApiServices/templateApi.service";
import { TemplateDataTypeEnum } from "../../../enum/Template.enum";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { colorArrayObjectConst } from "./JobListIndexConstColor";
import Input from "../../../components/Input/Input";
import CreateJobEndpoints from "../../../components/CreateJob/CreateJob.endpoints";
import { CreateTaskOperation } from "../../../enum/CreateTaskOperation.enum";
import Fade from "@material-ui/core/Fade";
import { extensionList } from "../../../constants/extensionList";
const useStyles = makeStyles({
  list: {
    width: 320,
    overflow: "hidden",
  },

  filterHeading: {
    fontWeight: "lighter",
  },
  cancel: {
    display: "flex",
    alignItems: "center",
    padding: "22px",
  },
  headerColumn: {
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    // webkitLineCla
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    // },
    maxWidth: "70px",
    // },
    // "&:hover": {
    //   overflow: "visible",
    //   display: "block",

    //   whiteSpace: "normal",
    //   height: "auto",
    // },
  },
  hide: {
    display: "none",
  },

  filter: {
    display: "inline-flex",
    borderRadius: "20px",
    // padding: "0px 15px 0px 5px",
    backgroundColor: "#f5f5f5",
    cursor: "pointer",
    margin: "0px 4px",
  },
  filterText: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9rem",
    color: "#666666",
  },
  columnEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tooltip: {
    "&::before": {
      borderBottomColor: "rgba(97, 97, 97, 0.92) !important",
    },
    fontSize: "10px",
  },
  tooltipTop: {
    "&::before": {
      borderTopColor: "rgba(97, 97, 97, 0.92) !important",
    },
    fontSize: "10px",
  },
  tooltipBottom: {
    "&::before": {
      borderBottomColor: "rgb(233, 233, 233, 0.92) !important",
    },
  },
  iconsTooltipBody: {
    height: "40px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
let templateListDataFirst: any = [];

const JobsListByRefrenceId = () => {
  const localStorageObject = useLocaStorage;
  // useLocaStorage.removeItem("filteredColumns");
  const { t, i18n } = useTranslation([
    "Jobslist",
    "Common",
    "Jobfilter",
    "Notifications",
  ]);
  const [jobStatusDataArray, setJobStatusDataArray]: any = useState([]);
  const [taskIdForTaskDetail, setTaskIdForTaskDetail]: any = useState([]);
  const [tagsBarcodeForTaskDetail, setTagsBarcodeForTaskDetail]: any = useState(
    []
  );
  const [inputChangeValue, setInputChangeValue]: any = useState();
  const colorArrayObject: any = colorArrayObjectConst;
  const [resetPage, setResetPage]: any = useState(0);
  const [templateId, setTemplateId]: any = useState([]);
  let [templateListData, setTemplateListData]: any = useState([]);
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const activatedExtension = useLocalStorage.getItem("activatedExtension");
  const isRouteOptimization = activatedExtension.includes(
    extensionList.routeOptimization.id
  );
  const [templateList, setTemplateList]: any = useState();
  const loader: any = useLoader();
  const toaster = useToast();
  const [refrenceIdArray, setRefrenceIdArray]: any = useState();
  const [rowJobId, setRowJobId] = useState();
  let [countRefreshDataTable, setCountRefreshDataTable] = useState(0);
  let [refreshTableState, setRefreshTableState] = useState(0);
  const filterJobData: any = localStorageObject.getItem("filter_job");
  const [search, setSearch]: any = useState();
  const [showBulkActionData, setShowBulkActionData] = useState([]);
  const Permission: any = localStorageObject.getItem("Permission");
  const [sort, setSort]: any = useState({
    sortColumn: "job_id",
    sortDirection: "DESC",
  });
  let [BadgeCount, setBadgeCount]: any = useState(0);
  let [searchBadgeCount, setSearchBadgeCount]: any = useState(false);
  let [statusCount, setStatusCount]: any = useState({});
  const isTemplateSet = useLocalStorage.getItem("selectedTemplateForJob");
  let expandCollapsePickup: boolean = false;
  const [isEditClicked, setIsEditClicked]: any = useState();
  const filteredColumns = useLocalStorage.getItem("filteredColumns");
  let expandCollapseDelivery: boolean = false;
  let expandCollapseService: boolean = false;
  let expandData: any = {
    delivery: false,
    pickup: false,
    service: false,
  };

  let [isListEmpty, setIsListEmpty]: any = useState(false);
  const defaultExtraParams = [
    {
      name: "fetch_task_details",
      value: true,
    },
    {
      name: "fetch_resource_details",
      value: true,
    },
    {
      name: "fetch_team_details",
      value: true,
    },
    {
      name: "fetch_status_count",
      value: true,
    },
    {
      name: "fetch_template_details",
      value: true,
    },
    {
      name: "fetch_jobs_by_reference_id",
      value: true,
    },
    {
      name: "fetch_tags_details",
      value: true,
    },
    {
      name: "fetch_additional_resources",
      value: true,
    },
  ];

  if (!isRouteOptimization) {
    defaultExtraParams.splice(7, 1);
  }

  if (isTemplateSet.length > 0) {
    defaultExtraParams.push({
      name: "template_id",
      value: isTemplateSet,
    });
  }
  const [
    extraParamsForJobListApiHit,
    setExtraParamsForJobListApiHit,
  ]: any = useState(defaultExtraParams);
  const filterIconRef: any = useRef();
  const bulkActionBtnRef: any = useRef();
  const createTaskRef: any = useRef();
  const deleteTaskRef: any = useRef();
  const openImportRef: any = useRef();
  const openExportRef: any = useRef();
  const bulkDeleteRef: any = useRef();

  const firstTimeRoActivation = useLocalStorage.getItem(
    "firstTimeRoActivation"
  );

  const reassignJobRef: any = useRef();
  const classes = useStyles();
  const [state, setState]: any = useState(false);
  const [showSearchFilter, setShowSearch]: any = useState(false);
  const [rowsData, setRowsData]: any = useState({
    allSelected: 0,
    data: [],
    refrenceIdArray: [],
    selectedCount: 0,
    selectedJobID: [],
  });
  const openCreateEditTask = (operation: number, jobId?: number) => {
    loader.showLoader();
    createTaskRef.current.handleOpen(operation, jobId);
  };
  const getSortData = (column: any, sortDirection: any) => {
    setSort({ sortColumn: column, sortDirection: sortDirection });
  };

  const getFilterData = (request: any, type?: any) => {
    loader.showLoader();
    drawerClose();
    setState(false);
    setShowSearch(false);
    if (type === "append" && request) {
      setJobStatusDataArray(request);
      setResetPage(1);
    }
    if (type && type !== "cancel") {
      let tempRequest = { ...useLocaStorage.getItem("filter_job") };
      let startDT: any;
      if (DateTimeFormatter.checkIfTimeInTz(tempRequest.start_datetime)) {
        startDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
          tempRequest.start_datetime
        );
      } else {
        startDT = tempRequest.start_datetime;
      }
      let endDT: any;
      if (DateTimeFormatter.checkIfTimeInTz(tempRequest.end_datetime)) {
        endDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
          tempRequest.end_datetime
        );
      } else {
        endDT = tempRequest.end_datetime;
      }
      if (startDT && endDT) {
        tempRequest.start_datetime = startDT;
        tempRequest.end_datetime = endDT;
      }

      for (const [key, value] of Object.entries(tempRequest)) {
        if (key === "team_id") {
          let tempValue: any = value;
          let team_ids: any = [];
          tempValue.map((team: any) => {
            team_ids.push(parseInt(team.value));
          });

          if (team_ids.length > 0) {
            request.push({
              name: key,
              value: team_ids,
            });
          }
        } else if (key !== "dateRadio")
          request.push({
            name: key,
            value: value,
          });
      }
    }
    if (search && (type !== "cancel" || type === "append")) {
      let searchPresent: any = false;
      request.map((val: any) => {
        if (val.name === "search") {
          searchPresent = true;
          setIsListEmpty(true);
        }
      });

      if (!searchPresent) {
        request.push({
          name: "search",
          value: search,
        });
        setIsListEmpty(false);
      }
    }

    if (request.length && request[0].name === "search" && !type) {
      setSearch(request[0].value);
      setSearchBadgeCount(true);
      setIsListEmpty(true);
    } else {
      setIsListEmpty(false);
      if (!search || type === "cancel") {
        setSearch();
        setSearchBadgeCount(false);
      }
    }

    let tempExtraParams = defaultExtraParams;

    if (type != "append" && jobStatusDataArray.length > 0) {
      tempExtraParams.push(jobStatusDataArray[0]);
    }
    // console.log(tempExtraParams);

    tempExtraParams.push(...request);
    for (let i = 0; i < tempExtraParams.length; i++) {
      if (
        tempExtraParams[i].name === "value" ||
        tempExtraParams[i].name === "period"
      ) {
        tempExtraParams.splice(i, 1);
        i--;
      }
    }

    setExtraParamsForJobListApiHit(tempExtraParams);
    let count_arr: any = [];
    request.map((c: any) => {
      if (
        c.name === "team_id" ||
        c.name === "job_status" ||
        c.name === "start_datetime" ||
        c.name === "get_tasks_with_unassigned_teams"
      ) {
        count_arr.push(c.name);
      }
    });
    if (!type) {
      setBadgeCount(count_arr.length);
    }
    refreshJobList();
    loader.hideLoader();
  };

  const toggleDrawer = (state: any) => () => {
    setState(!state);
  };
  const drawerClose = () => () => {
    setShowSearch(false);
    setState(false);
    setColumnFilterOpen(false);
  };

  const list = () => {
    return (
      <div className={clsx(classes.list)} role="presentation">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.cancel}>
            <img
              src={showSearchFilter ? SearchIcon : FilterIcon}
              style={{
                height: "20px",
                paddingRight: "8px",
              }}
            />
            <div className={"filterHeading"}>
              {showSearchFilter
                ? t("Jobfilter:Search")
                : t("Jobfilter:Filters")}
            </div>
          </div>
          <div
            style={{ paddingRight: "22px", cursor: "pointer" }}
            onClick={drawerClose()}
          >
            <img src={crossIcon} height={"22px"} />
          </div>
        </div>
        <JobListFilter
          getFilterData={getFilterData}
          search={search}
          showSearchOnly={showSearchFilter}
        />
      </div>
    );
  };

  const onConfirm = () => {
    let bulkAction = rowsData.selectedJobID.length > 0;
    let reqBody: any = {};
    if (bulkAction) {
      reqBody.job_id = rowsData.selectedJobID;
    } else {
      reqBody.job_id = rowJobId;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(
      bulkAction
        ? JobListEndpoints.bulkDeleteJobs(reqData)
        : CommonEndpoints.deleteJob(reqData)
    )
      .then((res: any) => {
        loader.hideLoader();
        if (bulkAction) {
          refreshJobListAndResetData();
        } else {
          refreshJobList();
        }

        deleteTaskRef.current && deleteTaskRef.current.handleClose();
        bulkDeleteRef.current && bulkDeleteRef.current.handleClose();

        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const onDiscard = () => {
    deleteTaskRef.current.handleClose();
    bulkDeleteRef.current && bulkDeleteRef.current.handleClose();
  };

  const refreshJobList = (count?: number) => {
    setCountRefreshDataTable(Math.floor(Math.random() * 100 + 1));
  };
  const refreshJobListAndResetData = async () => {
    await resetData();

    await refreshJobList();
  };

  const getJobsByTemplateId = async (id: any) => {
    let tempArray: any = [];
    tempArray.push(id);
    const defaultExtraParams = [
      {
        name: "fetch_task_details",
        value: true,
      },
      {
        name: "fetch_resource_details",
        value: true,
      },
      {
        name: "fetch_team_details",
        value: true,
      },
      {
        name: "fetch_status_count",
        value: true,
      },
      {
        name: "fetch_jobs_by_reference_id",
        value: true,
      },
      {
        name: "fetch_tags_details",
        value: true,
      },
      {
        name: "fetch_template_details",
        value: true,
      },
      {
        name: "fetch_additional_resources",
        value: true,
      },
    ];
    if (id?.length > 0) {
      defaultExtraParams.push({
        name: "template_id",
        value: id,
      });
    }
    setExtraParamsForJobListApiHit(defaultExtraParams);
  };
  const resetData = () => {
    // setTimeout(() => {
    let tempData = { ...rowsData };
    tempData.selectedCount = 0;
    tempData.selectedJobID = [];
    tempData.allSelected = false;
    setRowsData(tempData);
    // }, 700)
  };
  const getTemplateList = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      skip: 0,
      limit: 1000,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    await ApiService.get(templateApiService.getTemplateList(reqData))
      .then((res) => {
        let templateFields = res?.data?.data;
        let selectedTemplateList: any = [],
          tempArray2: any = [],
          tempArray: any = [];

        if (templateFields) {
          for (let i = 0; i < templateFields.length; i++) {
            for (let k = 0; k < templateFields[i].data.length; k++) {
              tempArray2.push({
                template_id: templateFields[i].template_id,
                template_name: templateFields[i].name,
                field_name: templateFields[i].data[k].name,
              });
            }
            if (isTemplateSet) {
              for (let y = 0; y < isTemplateSet.length; y++) {
                if (templateFields[i].template_id == isTemplateSet[y]) {
                  selectedTemplateList.push({
                    name: templateFields[i].name,
                    id: templateFields[i].template_id,
                  });
                }
              }
            }
          }
          for (let k = 0; k < tempArray2.length; k++) {
            for (let i = 0; i < selectedTemplateList.length; i++) {
              if (tempArray2[k].template_id == selectedTemplateList[i].id) {
                tempArray.push(tempArray2[k]);
              }
            }
          }
          templateListDataFirst = tempArray;
          if (!filteredColumns) {
            useLocalStorage.removeItem("filteredColumns");
            setColumnFilteredList(setDynamicColumnsFilterList(tempArray));
          }
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const selectedTemplate = useLocalStorage.getItem("selectedTemplateForJob");
    if (selectedTemplate) {
      getTemplateList();

      getJobsByTemplateId(selectedTemplate);
      setCountRefreshDataTable(++countRefreshDataTable);
    }
  }, []);
  const eventFromService = (eventData: any) => {
    switch (eventData.type) {
      case "refreshTaskList":
        {
          refreshJobListAndResetData();
        }
        break;
      case "refreshList":
        refreshJobList();
        break;
      case "templateList":
        getJobsByTemplateId(eventData.data.tag_id);
        setCountRefreshDataTable(++countRefreshDataTable);
        break;
      case "templateListDataApiHit":
        // useLocaStorage.removeItem("filteredColumns");
        let tempArray4: any = [];
        for (let k = 0; k < eventData.data.data.length; k++) {
          for (let i = 0; i < isTemplateSet.length; i++) {
            if (eventData.data.data[k].template_id == isTemplateSet[i]) {
              tempArray4.push(eventData.data.data[k]);
            }
          }
        }
        setTemplateListData(tempArray4);
        // setColumnFilteredList(setDynamicColumnsFilterList(tempArray4));

        break;
      case "templateListData":
        setTemplateListData(eventData.data.data);
        if (eventData.isReset) {
          useLocaStorage.removeItem("filteredColumns");
          setColumnFilteredList(
            setDynamicColumnsFilterList(eventData.data.data)
          );
        }
        break;
      case "openImportModal":
        openImportRef.current.handleOpen(0);
        break;
      case "openExportModal":
        openExportRef.current.handleOpenClick("jobsList");
        break;
      case "clickedOnCreateTask":
        createTaskRef.current.handleOpen(0);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    // headerDropdownService.subscribe(eventFromService);
    if (Object.keys(filterJobData).length) {
      getExtraParameters();
    }
    if (useLocalStorage.getItem("filteredColumns")) {
      getColumnFilter();
    }
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  const getExtraParameters = () => {
    let tempExtraParams = defaultExtraParams;
    let count = 0;
    if (filterJobData.hasOwnProperty("team_id")) {
      setBadgeCount(++count);
      let TeamIds: any = [];
      let team_arr: any = filterJobData.team_id;
      team_arr.map((team: any) => {
        TeamIds.push(parseInt(team.value));
      });
      if (TeamIds.length > 0) {
        tempExtraParams.push({
          name: "team_id",
          value: TeamIds,
        });
      }
    }
    if (filterJobData.hasOwnProperty("status_id")) {
      setBadgeCount(++count);
      let StatusIds: any = [];
      let status_arr: any = filterJobData.status_id;
      StatusIds = status_arr.map((status: any) => {
        return parseInt(status.value);
      });
      tempExtraParams.push({
        name: "job_status",
        value: StatusIds,
      });
    }
    if (filterJobData.hasOwnProperty("start_datetime")) {
      setBadgeCount(++count);

      let startDT: any;
      if (DateTimeFormatter.checkIfTimeInTz(filterJobData.start_datetime)) {
        startDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
          filterJobData.start_datetime
        );
      } else {
        startDT = filterJobData.start_datetime;
      }
      if (startDT) {
        tempExtraParams.push({
          name: "start_datetime",
          value: startDT,
        });
      }
    }
    if (filterJobData.hasOwnProperty("end_datetime")) {
      let endDT: any;
      if (DateTimeFormatter.checkIfTimeInTz(filterJobData.end_datetime)) {
        endDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
          filterJobData.end_datetime
        );
      } else {
        endDT = filterJobData.end_datetime;
      }
      if (endDT) {
        tempExtraParams.push({
          name: "end_datetime",
          value: endDT,
        });
      }
    }

    setExtraParamsForJobListApiHit(tempExtraParams);
  };
  const openEditTask = (row: any) => {
    loader.showLoader();
    openCreateEditTask(1, row.job_id);
  };
  const openDuplicateTask = (row: any) => {
    loader.showLoader();
    openCreateEditTask(2, row.job_id);
  };
  const openReassignJob = (row: any) => {
    reassignJobRef.current.handleOpen(row.job_id);
  };
  const openDeleteJob = (row: any) => {
    if (row.length > 0) {
    } else {
      setRowJobId(row.job_id);
    }
    deleteTaskRef.current.handleClickOpen();
  };
  const ITEM_HEIGHT = 48;
  const options = ["Edit", "Reassign Resource", "Duplicate", "Delete"];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openTaskDetail = (taskId: number, tagsBarcodeObj?: any) => {
    setTaskIdForTaskDetail(taskId);

    setTagsBarcodeForTaskDetail(tagsBarcodeObj);

    setShowTaskDetail(true);
  };
  const TaskWithStatusColor = (rowObj: any) => {
    const row = rowObj.row;
    console.log(row, "2323223223");

    return (
      <div
        style={{ position: "relative" }}
        // onClick={() => {
        //   openTaskDetail(row.tasks[0]["task_id"]);
        // }}
        className="jobList__job-id"
        // style={{
        //   backgroundColor: jobStatusData[row.job_status]["gradientColor"],
        // }}
      >
        <div
          className="jobList__job-id__status-circle-dot"
          style={{ backgroundColor: jobStatusData[row.job_status]["color"] }}
        ></div>
        <div className="jobList__job-id__status-text">
          {row ? t(`Common:${jobStatusData[row.job_status].name}`) : ""}
        </div>
        {/* {row["job_id"]} */}
      </div>
    );
  };

  const exportJobAsPDF = (row?: any) => {
    let reqBody: any = {
      job_id: JSON.stringify(
        rowsData.selectedJobID.length === 0
          ? row.job_id
          : rowsData.selectedJobID
      ),
    };
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: headers,
    };

    loader.showLoader();
    const timer = setTimeout(() => {
      loader.hideLoader();
      toaster.addToast({
        message: "Job export running in background",
        timeout: 3000,
        type: "success",
      });
    }, 5000);
    downloadDataAsPDF(reqData, () => {
      if (rowsData.selectedJobID.length > 0) {
        resetData();
      }
      clearTimeout(timer);
      loader.hideLoader();
    });
  };
  const JobListAction = (rowObj: any) => {
    const row = rowObj.row;
    const actionList: any = [];
    if (Permission && Permission["Update_Jobs"]) {
      actionList.splice(0, 0, {
        name: t("Common:Edit"),
        actionHandler: openEditTask,
      });
      actionList.splice(2, 0, {
        name: t("Common:Update"),
        actionHandler: openReassignJob,
      });
    }
    if (Permission && Permission["Create_Jobs"]) {
      actionList.splice(1, 0, {
        name: t("Common:Duplicate"),
        actionHandler: openDuplicateTask,
      });
    }
    if (Permission && Permission["Read_Jobs"]) {
      actionList.splice(3, 0, {
        name: t("Common:Export"),
        actionHandler: exportJobAsPDF,
      });
    }
    if (Permission && Permission["Delete_Jobs"]) {
      actionList.splice(4, 0, {
        name: t("Common:Delete"),
        actionHandler: openDeleteJob,
      });
    }

    return <DatableActionList row={row} actionList={actionList} />;
  };

  const showEllipsisTooltipOnText = (data: any) => {
    if (data && data.length > 10) {
      return (
        <Tooltip
          content={data}
          direction={"bottom"}
          style={{
            backgroundColor: "rgba(97, 97, 97, 0.92)",
            marginBottom: "-8px",
          }}
          wrapperStyle={{
            width: "-webkit-fill-available",
          }}
          className={classes.tooltip}
        >
          <div className={classes.columnEllipsis}>{data}</div>
        </Tooltip>
      );
    } else {
      return <span>{data}</span>;
    }
  };

  const expandFilterChange = (type: any, value: boolean) => {
    expandData = useLocalStorage.getItem("expandCollapseFilter") || expandData;
    if (type === 0) {
      expandData.pickup = !expandData.pickup;
      // expandCollapsePickup = value;
    } else if (type === 1) {
      expandData.delivery = !expandData.delivery;
      // expandCollapseDelivery = value;
    } else {
      expandData.service = !expandData.service;
      // expandCollapseService = value;
    }
    useLocalStorage.setItem("expandCollapseFilter", expandData);
    setRefreshTableState(++refreshTableState);
  };

  const dataTableTooltip = (type: any, headerTitle: any, value?: any) => {
    let temp = useLocalStorage.getItem("expandCollapseFilter");
    let selectedValue: any = false;
    if (temp) {
      if (type === 0) {
        selectedValue = temp.pickup;
      } else if (type === 1) {
        selectedValue = temp.delivery;
      } else if (type === 2) {
        selectedValue = temp.service;
      }
    }
    if (value) {
      selectedValue = value;
    }
    return (
      <div className={classes.iconsTooltipBody}>
        <span>{headerTitle}</span>
        <div>
          <Tooltip
            content={selectedValue ? "Collapse All" : "Expand All"}
            direction={"bottom"}
            style={{
              backgroundColor: "#e9e9e9",
              marginBottom: "-12px",
              color: "black",
              marginLeft: "3px",
            }}
            wrapperStyle={{
              width: "-webkit-fill-available",
            }}
            className={classes.tooltipBottom}
          >
            <img
              style={{
                height: "15px",
                marginLeft: "5px",
              }}
              onClick={() => {
                expandFilterChange(type, !selectedValue);
                changeExpandCollapseIcon(type, selectedValue, headerTitle);
              }}
              src={selectedValue ? CollapseIcon : ExpandIcon}
            ></img>
          </Tooltip>
        </div>
      </div>
    );
  };
  const changeExpandCollapseIcon = (type: any, value: any, title: any) => {
    let temp: any = [];
    let tempcolumnFilterlist: any = [...columnFilteredList];
    if (appData.loginData.workflow_type === 1) {
      let index: any = [];
      for (let i = 0; i < tempcolumnFilterlist.length; i++) {
        if (
          tempcolumnFilterlist[i].key === "pickupDetails__column" &&
          tempcolumnFilterlist[i].value
        ) {
          index.push(i);
        }
        if (
          tempcolumnFilterlist[i].key === "deliveryDetails__column" &&
          tempcolumnFilterlist[i].value
        ) {
          index.push(i);
        }
      }

      if (index.length > 0) {
        tempcolumnFilterlist.splice(index[0], 2);
      }
    } else {
      let index = tempcolumnFilterlist.findIndex((i: any, index: any) => {
        if (i.key === "serviceDetails__column") {
          return index;
        }
      });

      if (index > 0) {
        tempcolumnFilterlist.splice(index, 1);
      }
    }
    if (tempcolumnFilterlist) {
      columns.map((val: any, index: any) => {
        if (
          val.key === tempcolumnFilterlist[index].key &&
          tempcolumnFilterlist[index].value === true
        ) {
          temp.push(val);
        }
      });
    }
    setColumnFilteredList(tempcolumnFilterlist);

    temp.map((val: any, index: any) => {
      if (
        (val.key === "pickupDetails__column" && type === 0) ||
        (val.key === "deliveryDetails__column" && type === 1) ||
        (val.key === "serviceDetails__column" && type === 2)
      ) {
        let temp1 = useLocalStorage.getItem("expandCollapseFilter");
        let selectedValue = false;
        if (temp1) {
          if (type === 0) {
            selectedValue = temp1.pickup;
          } else if (type === 1) {
            selectedValue = temp1.delivery;
          } else if (type === 2) {
            selectedValue = temp1.service;
          }
        }
        temp[index].name = dataTableTooltip(type, title, selectedValue);
      }
    });
    setIsDataFiltered(true);
    setFilteredColumnsData(temp);
    setRefreshTableState(++refreshTableState);
  };
  const handleSelectAllClicked = (evt: any) => {
    let tempRows: any = { ...rowsData };

    tempRows.allSelected = evt.target.checked;
    let newArr: any = [];
    let tempCount = tempRows.selectedCount;
    let tempSelectedJobId: any = [...rowsData.selectedJobID];
    tempRows.data.map((row: any, index: any) => {
      newArr.push({ job_id: row.job_id, checked: evt.target.checked });
      if (evt.target.checked) {
        tempCount++;
        if (!tempSelectedJobId.includes(row.job_id)) {
          tempSelectedJobId.push(row.job_id);
        }
      }
    });
    if (!evt.target.checked) {
      let tempSelectedJobIdArr: any = [];
      for (let i = 0; i < tempRows.data.length; i++) {
        for (let j = 0; j < tempSelectedJobId.length; j++) {
          if (tempRows.data[i].job_id === tempSelectedJobId[j]) {
            tempSelectedJobId.splice(j, 1);
            j--;
            tempCount--;
          }
        }
      }
    }

    tempRows.selectedCount = tempCount;
    tempRows.selectedJobID = tempSelectedJobId;
    tempRows.data = [...newArr];
    setRowsData({ ...tempRows });
  };
  const handleSingleCheckboxChange = async (index: any, value: any) => {
    let tempRows = { ...rowsData };
    let tempCount = tempRows.selectedCount;

    let tempSelectedJobId: any = [...tempRows.selectedJobID];
    if (value) {
      tempCount++;
      tempSelectedJobId.push(index.job_id);
    } else {
      tempCount--;
      let i = tempSelectedJobId.indexOf(index.job_id);
      tempSelectedJobId.splice(i, 1);
    }
    tempRows.selectedJobID = tempSelectedJobId;
    let res: any = await checkAllSelected(tempRows.data, tempSelectedJobId);
    tempRows.allSelected = res;

    let i = tempRows.data.findIndex(
      (element: any) => element.job_id === index.job_id
    );
    tempRows.data[i].checked = value;
    tempRows.selectedCount = tempCount;

    setRowsData({ ...tempRows });
  };
  let templateValueObject = {
    templateValue: "",
  };
  const getValueForSelectedRow = (
    rowData: any,
    totalRowData: any,
    templateName?: any
  ) => {
    if (rowData.name == "Cabinet Counts") {
      return (
        <>
          <span
            style={{ display: "none" }}
            id={"edit-save-icon" + totalRowData.job_id}
          >
            <Input
              // value={
              //   inputChangeValue?.jobId
              //     ? inputChangeValue.jobId == row["job_id"]
              //       ? inputChangeValue.templateValue
              //       : row["job_id"]
              //     : row["job_id"]
              // }
              type={rowData.data_type == 0 ? "text" : "number"}
              value={
                templateValueObject?.templateValue
                  ? templateValueObject.templateValue
                  : rowData?.resource_value
                  ? rowData.resource_value
                  : rowData.value
                  ? rowData.value
                  : ""
              }
              onchange={(evt: any) => {
                console.log(evt.target.value, "On Change Value");
                templateValueObject.templateValue = evt.target.value;
                // setInputChangeValue({
                //   templateValue: evt.target.value,
                // });
              }}
            ></Input>

            <CheckCircleIcon
              style={{
                float: "right",
                cursor: "pointer",
                color: "green",
                alignSelf: "center",
                marginBottom: "8px",
              }}
              onClick={() => {
                setIsEditClicked(false);
                updateJobRowData(templateValueObject, totalRowData);
              }}
            ></CheckCircleIcon>
          </span>
          <span
            style={{ display: "flex" }}
            id={"edit-icon" + totalRowData.job_id}
          >
            {rowData?.resource_value
              ? rowData.resource_value
              : rowData.value
              ? rowData.value
              : ""}

            <span
              onClick={() => {
                setIsEditClicked(true);
                setTimeout(() => {
                  //@ts-ignore
                  document.getElementById(
                    `edit-save-icon${totalRowData.job_id}`
                  ).style.display = "flex";
                  //@ts-ignore
                  document.getElementById(
                    `edit-icon${totalRowData.job_id}`
                  ).style.display = "none";
                }, 0);
              }}
            >
              <EditIcon
                style={{ color: `${themeColor.primary.main}`, height: "14px" }}
              />
            </span>
          </span>
        </>
      );
    }

    if (
      rowData.data_type == TemplateDataTypeEnum.IMAGE ||
      rowData.data_type == TemplateDataTypeEnum.DOCUMENT
    ) {
      let imageArrayToShow: any = [],
        resourceValue = rowData?.value,
        image,
        lengthOfResource = rowData?.resource_value
          ? rowData?.resource_value.length
          : resourceValue?.length;
      if (rowData.resource_value) {
        resourceValue = rowData?.resource_value;
      }
      // image = `<div style="display:flex;flex-direction:column">`
      for (let index = 0; index < lengthOfResource; index++) {
        image = (
          <>
            <a href={resourceValue[index]} target="_blank">
              {rowData.data_type == TemplateDataTypeEnum.IMAGE
                ? "View Photo"
                : " View Document"}
            </a>
          </>
        );
        imageArrayToShow.push(image);
      }
      return imageArrayToShow;
    } else {
      let valueOfRow: any, countryCode: any;
      if (rowData.value || rowData?.resource_value) {
        if (rowData?.resource_value) {
          valueOfRow = rowData.resource_value;
        } else {
          valueOfRow = rowData.value;
        }
        if (rowData.data_type == TemplateDataTypeEnum.RECORDING) {
          let image = (
            <>
              <a href={rowData.value} target="_blank">
                {"View Recording"}
              </a>
            </>
          );
          return image;
        }
        if (rowData.data_type == TemplateDataTypeEnum.PHONENUMBER) {
          countryCode = rowData.country_code;

          return "(" + countryCode + ")" + valueOfRow;
        }
      }
      return valueOfRow;
    }
  };
  const GetDetails = (rowData: any, templateFieldName: any) => {
    if (rowData) {
      let rowDataForLoop = rowData.rowData;
      for (let i = 0; i < rowDataForLoop.length; i++) {
        if (rowDataForLoop[i]?.template) {
          for (let k = 0; k < rowDataForLoop[i]?.template?.data?.length; k++) {
            if (
              rowDataForLoop[i].template.data[k].name ==
              rowData?.templateFieldName
            ) {
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getValueForSelectedRow(
                    rowDataForLoop[i].template.data[k],
                    rowData.row,
                    rowDataForLoop[i]?.template?.name
                  )}
                </div>
              );
            }
          }
        }
      }
    }
    return <>-</>;
  };

  const getDriverAndAdditionalResources = (rowData: any) => {
    let isRouteJob = rowData?.route_id,
      dataObject: any = {
        resourceName: "",
        additionalDriver: "",
        assetName: "",
        vehicleName: "",
      };
    if (isRouteJob) {
      let resourceId = rowData?.resource_id;
      // if (resourceId) {
      for (
        let index = 0;
        index < rowData?.additional_resources?.length;
        index++
      ) {
        // resourceType 1 == Driver , 2 == Vehcile , 3 == Asset
        if (
          rowData?.additional_resources[index]?.resource_id === resourceId &&
          rowData?.additional_resources[index]?.resource_type === 1
        ) {
          dataObject.resourceName =
            rowData?.additional_resources[index]?.name ||
            rowData?.additional_resources[index]?.res_name;
        } else if (
          rowData?.additional_resources[index]?.resource_id != resourceId &&
          rowData?.additional_resources[index]?.resource_type === 1
        ) {
          dataObject.additionalDriver =
            rowData?.additional_resources[index]?.name ||
            rowData?.additional_resources[index]?.res_name;
        } else if (rowData?.additional_resources[index]?.resource_type == 2) {
          dataObject.vehicleName =
            dataObject.vehicleName +
            (dataObject.vehicleName == "" ? "" : ",") +
            (rowData?.additional_resources[index]?.res_name ||
              rowData?.additional_resources[index]?.name ||
              rowData?.additional_resources[index]?.licence_plate);
        } else if (rowData?.additional_resources[index]?.resource_type == 3) {
          dataObject.assetName =
            dataObject.assetName +
            (dataObject.assetName === "" ? "" : ",") +
            (rowData?.additional_resources[index]?.res_name ||
              rowData?.additional_resources[index]?.name ||
              rowData?.additional_resources[index]?.asset_id);
        }
        // }
      }
    }
    return (
      <div>
        {dataObject.resourceName ? (
          <>
            {" "}
            <b>Agent :</b>
            <span> {dataObject.resourceName}</span>
            <br></br>
          </>
        ) : null}
        {dataObject.additionalDriver ? (
          <>
            {" "}
            <b>Add. Resource :</b>
            <span> {dataObject.additionalDriver}</span>
            <br></br>
          </>
        ) : null}
        {dataObject.vehicleName ? (
          <>
            {" "}
            <b>Vehcile :</b>
            <span> {dataObject.vehicleName}</span>
            <br></br>
          </>
        ) : null}
        {dataObject.assetName ? (
          <>
            {" "}
            <b>Asset :</b>
            <span> {dataObject.assetName}</span>
          </>
        ) : null}
      </div>
    );
  };
  const updateJobRowData = async (templateInputValue: any, rowDatas: any) => {
    // let rowData = rowDatas;
    if (
      templateInputValue?.templateValue ||
      templateInputValue.templateValue == ""
    ) {
      let rowData: any = {};
      for (
        let index = 0;
        index < rowDatas.tasks[0].template.data.length;
        index++
      ) {
        if (rowDatas.tasks[0].template.data[index].name == "Cabinet Counts") {
          rowDatas.tasks[0].template.data[index].value =
            templateInputValue.templateValue;
        }
      }

      const headers = {
        access_token: appData.loginData.access_token,
      };

      for (const key in rowDatas) {
        if (rowDatas.hasOwnProperty(key)) {
          var value = rowDatas[key];
          //do something with value;
          if (value == null) {
            console.log([value, key], "ewewew2323");
            // delete rowData[key]
          } else {
            if (key == "tasks") {
              rowData["task_details"] = value;
            } else {
              rowData[key] = value;
            }
          }
        }
      }

      for (
        let index = 0;
        index < rowDatas.tasks[0].template.data.length;
        index++
      ) {
        if (rowDatas.tasks[0].template.data[index]?.resource_value) {
          delete rowDatas.tasks[0].template.data[index]?.resource_value;
        }
      }

      rowData.task_details[0].phone = Number(rowData.task_details[0].phone);
      // let newDate = new Date(rowData.task_details[0].local_datetime)
      // rowData.task_details[0].local_datetime = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
      //   rowData.task_details[0].local_datetime,
      //   "YYYY-MM-DD HH:mm"
      // );
      // rowData.task_details[0].local_datetime=rowData.task_details[0].local_datetime;
      rowData.task_details[0].lat = Number(rowData.task_details[0].lat);
      rowData.task_details[0].lng = Number(rowData.task_details[0].lng);
      delete rowData.task_details[0].capacity;
      rowData["action_type"] = 0;
      delete rowData["assignment_status"];
      delete rowData["auto_assign"];
      delete rowData["created_at"];
      delete rowData["creation_source"];
      delete rowData["end_datetime"];
      delete rowData["user_image"];
      delete rowData["enp"];
      delete rowData["start_datetime"];
      delete rowData["user_id"];
      delete rowData["utc_start_datetime"];
      delete rowData["team_name"];
      delete rowData["address"];
      delete rowData["user_name"];
      delete rowData["res_country_code"];
      delete rowData["res_phone"];
      delete rowData["res_email"];
      delete rowData["res_lat"];
      delete rowData["res_name"];
      delete rowData["res_lng"];
      delete rowData["status"];
      delete rowData["route_id"];
      delete rowData["utc_end_datetime"];
      delete rowData["tags"];

      delete rowData["is_intransit"];
      delete rowData["is_active"];

      delete rowData.task_details[0].capacity;
      delete rowData.task_details[0].comments;
      delete rowData.task_details[0].enp;
      delete rowData.task_details[0].otp;
      if (rowData.task_details[0].email == null) {
        delete rowData.task_details[0].email;
      }
      delete rowData.task_details[0].customer_id;
      delete rowData.task_details[0].distance_travelled;
      delete rowData.task_details[0].end_datetime;
      delete rowData.task_details[0].failure_reason;
      delete rowData.task_details[0].geofence_id;
      delete rowData.task_details[0].is_otp_verified;
      delete rowData.task_details[0].local_datetime;

      delete rowData.task_details[0].job_id;
      delete rowData.task_details[0].rating;
      delete rowData.task_details[0].service_time;
      if (rowData.task_details[0].task_description == null) {
        delete rowData.task_details[0].task_description;
      }
      delete rowData.task_details[0].user_id;
      delete rowData.task_details[0].status;
      delete rowData.task_details[0].task_status;
      delete rowData.task_details[0].team_id;
      delete rowData.task_details[0].template.name;
      delete rowData.task_details[0].template_name;
      delete rowData.task_details[0].utc_datetime;
      delete rowData.task_details[0].utc_end_datetime;
      delete rowData.task_details[0].is_return_task;
      if (rowData.task_details[0].resource_id == null) {
        rowData["resource_id"] = 0;
      } else {
        rowData["resource_id"] = rowData.task_details[0].resource_id;
      }
      delete rowData.task_details[0].resource_id;
      const reqData = {
        reqBody: rowData,
        headers: headers,
      };
      await ApiService.put(
        CreateJobEndpoints.crateJob(reqData, CreateTaskOperation.EDIT)
      )
        .then(function (response: any) {
          toaster.addToast({
            message: t("Extensions:Update Sucessfully"),
            timeout: 3000,
            type: "success",
          });
        })
        .catch((err: any) => {
          toaster.addToast({
            message: err.message,
            timeout: 3000,
            type: "error",
          });
        });
    } else {
      toaster.addToast({
        message: "Please Update Value",
        timeout: 3000,
        type: "error",
      });
    }
  };
  let columns: any = [
    {
      name: (
        <Checkbox
          checked={rowsData.allSelected}
          color="primary"
          onClick={(e: any) => {
            handleSelectAllClicked(e);
          }}
        />
      ),
      key: "checked",
      selector: "checked",
      cell: (row: any, i: any) => (
        <Checkbox
          color="primary"
          checked={rowsData.selectedJobID.includes(row.job_id)}
          onChange={(e: any) => {
            handleSingleCheckboxChange(row, e.target.checked);
          }}
        />
      ),
      center: "true",
      width: "50px",
    },
    {
      name: t("Jobslist:Job ID"),
      // selector: "job_id",
      key: "jobId__column",
      sortable: true,
      cell: (row: any) => (
        <div
          onClick={() => {
            openTaskDetail(row.tasks[0]["task_id"], {
              tags: row.tags || "",
              barcode: row.barcode || "",
              assignmentStatus: row.assignment_status,
            });
          }}
          className="jobList__auto-assignment-action"
        >
          {row ? row["job_id"] : ""}
        </div>
      ),
      center: "true",
      width: "75px",
    },
    {
      name: t("Common:Reference ID"),
      selector: "reference_id",
      key: "referenceId__column",
      minWidth: "90px",
      center: "true",
      cell: (row: any) =>
        row.tasks[0]
          ? showEllipsisTooltipOnText(row.tasks[0]["reference_id"])
          : "",
    },

    {
      name: t("Jobslist:Job Status"),
      selector: "job_status",
      key: "jobStatus__column",
      cell: (row: any) => <TaskWithStatusColor row={row} />,
      center: "true",
      minWidth: "110px",
    },

    {
      name: t("Jobslist:Team Name"),
      selector: "team_name",
      key: "teamName__column",
      wrap: true,
      sortable: true,
      center: "true",
      minWidth: "50px",
    },
    {
      name: t("Jobslist:Agent Details"),
      selector: "user_name",
      sortable: true,
      key: "resourceDetails__column",
      cell: (row: any) => (
        <ResourceDetailsInJobTable refreshJobList={refreshJobList} row={row} />
      ),
      center: "true",
      minWidth: "50px",
    },
    {
      name: t("Jobslist:Resources"),
      selector: "additional_resources",
      key: "ROdriver_column",
      wrap: true,
      cell: (row: any) => getDriverAndAdditionalResources(row),
      sortable: true,
      center: "true",
      minWidth: "200px",
    },
    {
      name: t("Jobslist:Created On"),
      selector: "created_at",
      wrap: true,
      sortable: true,
      key: "creationDatetime__column",
      cell: (row: any) => <CreateionDateTimeForJobList row={row} />,
      center: "true",
      minWidth: "110px",
    },
  ];

  if (!isRouteOptimization || (firstTimeRoActivation && !filteredColumns)) {
    columns.splice(6, 1);
  }

  if (templateListData) {
    if (templateListData.length == 0) {
      templateListData = templateListDataFirst;
    }
    if (isTemplateSet?.length > 0) {
      for (let i = 0; i < templateListData.length; i++) {
        columns.splice((isRouteOptimization ? 8 : 7) + i, 0, {
          name: (
            <>
              <ToolTipMaterial
                placement="top"
                title={templateListData[i].field_name}
              >
                <span className={classes.headerColumn}>
                  {templateListData[i].field_name}
                </span>
              </ToolTipMaterial>
            </>
          ),
          // selector: "hello",
          key: templateListData[i].field_name + (i + 1),
          wrap: true,
          cell: (row: any) => (
            <>
              <GetDetails
                rowData={row.tasks}
                templateFieldName={templateListData[i].field_name}
                row={row}
              ></GetDetails>
            </>
          ),
          sortable: true,
          center: "true",
          // id: "templateList_column",
          minWidth:
            templateListData[i].field_name == "Cabinet Counts"
              ? "100px"
              : "50px",
          maxWidth: "100px",
        });
      }
    }

    columns.splice((isRouteOptimization ? 8 : 7) + templateListData.length, 0, {
      name: t("Common:Actions"),
      allowOverflow: true,
      button: true,
      selector: "team_name",
      key: "actions__column",
      cell: (row: any, i: any) =>
        Permission &&
        (Permission["Update_Jobs"] ||
          Permission["Create_Jobs"] ||
          Permission["Delete_Jobs"]) ? (
          <>
            {rowsData.selectedJobID.length === 0 ? (
              <JobListAction row={row} />
            ) : (
              <></>
            )}
          </>
        ) : (
          ""
        ),
      center: "true",
      minWidth: "60px",
    });
  }

  // }

  // }
  if (
    !appData.loginData.workflow_type ||
    appData.loginData.workflow_type == 2
  ) {
    columns.splice(isRouteOptimization ? 7 : 6, 0, {
      name: <div>{dataTableTooltip(0, t("Jobslist:Pickup Details"))}</div>,
      selector: "location",
      key: "pickupDetails__column",
      cell: (row: any) => (
        <ShowTaskDetailsInHtmlView
          tasks={row.tasks}
          taskType={0}
          expandCollapseAll={expandData.pickup}
        />
      ),
      minWidth: "155px",
    });
    columns.splice(isRouteOptimization ? 8 : 7, 0, {
      name: <div>{dataTableTooltip(1, t("Jobslist:Delivery Details"))}</div>,
      selector: "location",
      key: "deliveryDetails__column",
      cell: (row: any) => (
        <ShowTaskDetailsInHtmlView
          tasks={row.tasks}
          taskType={1}
          expandCollapseAll={expandData.delivery}
        />
      ),
      minWidth: "155px",
    });
  } else {
    columns.splice(isRouteOptimization ? 7 : 6, 0, {
      name: <div>{dataTableTooltip(2, t("Jobslist:Service Details"))}</div>,
      selector: "location",
      key: "serviceDetails__column",
      cell: (row: any) => (
        <ShowTaskDetailsInHtmlView
          tasks={row.tasks}
          taskType={2}
          expandCollapseAll={expandData.service}
        />
      ),
      minWidth: "155px",
    });
  }

  const [columnFilterOpen, setColumnFilterOpen] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  if (useLocalStorage.getItem("filteredColumns")) {
    const tempColumnList: any = [];
    columns.map((val: any, index: any) => {
      if (
        val?.key === useLocalStorage.getItem("filteredColumns")[index]?.key &&
        useLocalStorage.getItem("filteredColumns")[index].value === true
      ) {
        tempColumnList.push({ ...val });
      }
    });
    columns = [...tempColumnList];
  }

  const [filteredColumnsData, setFilteredColumnsData] = useState(columns);

  const setColumnsForList = () => {
    const tempColumnList: any = [];
    const filteredColumn = useLocaStorage.getItem("filteredColumns");
    columns.map((val: any, index: any) => {
      if (
        val?.key === filteredColumn[index]?.key &&
        filteredColumn[index].value === true
      ) {
        tempColumnList.push({ ...val });
      }
    });

    setIsDataFiltered(true);
    setFilteredColumnsData(tempColumnList);
  };
  const columnDrawerClose = () => {
    setColumnFilterOpen(false);
    if (useLocalStorage.getItem("filteredColumns")) {
      setColumnFilteredList(useLocalStorage.getItem("filteredColumns"));
    } else {
      setColumnFilteredList(setDynamicColumnsFilterList());
    }
  };
  useEffect(() => {
    getJobList();
  }, []);

  const getJobList = async () => {
    let isResourceTrue: any = useLocalStorage.getItem("isResourceFetchTrue");
    isResourceTrue = isResourceTrue == 1 ? true : false;
    let qureyParams = {
      fetch_template_details: true,
      order_by: "DESC",
      fetch_tags_details: true,
      fetch_team_details: true,
      fetch_resource_details: true,
      fetch_status_count: true,
      order_column: "job_id",
      fetch_additional_resources: isResourceTrue,
      limit: 10,
      skip: 0,
      template_id: isTemplateSet,
    };
    if (!isRouteOptimization) {
      delete qureyParams?.fetch_additional_resources;
    }
    if (!isTemplateSet || isTemplateSet?.length == 0) {
      delete qureyParams.template_id;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: qureyParams,
      headers: headers,
    };

    const res = await ApiService.get(
      CommonEndpoints.getJobDetailByTemplate(reqData)
    );
    setTemplateList(res.data);
  };
  const setDynamicColumnsFilterList = (data?: any) => {
    let dynamicColumnsFilterList: any = [];

    JobColumnList(t).map((val: any) => {
      dynamicColumnsFilterList.push({
        name: val.name,
        value: val.name == "Resources" ? false : true,
        template_name: "",
        key: val.key,
      });
    });
    let i: any = 0;
    if (data) {
      data.map((value: any) => {
        i++;
        dynamicColumnsFilterList.push({
          name: value.field_name,
          value: true,
          template_name: value.template_name ? value.template_name : "",

          key: value.field_name + i,
        });
      });
    } else {
      if (isTemplateSet?.length > 0) {
        templateListData.map((value: any) => {
          i++;
          dynamicColumnsFilterList.push({
            name: value.field_name,
            value: true,
            template_name: value.template_name ? value.template_name : "",
            key: value.field_name + i,
          });
        });
      }
    }
    dynamicColumnsFilterList.push({
      name: "Actions",
      value: true,
      template_name: "",
      key: "actions__column",
    });
    return dynamicColumnsFilterList;
  };

  const [columnFilteredList, setColumnFilteredList] = useState(
    filteredColumns ? filteredColumns : setDynamicColumnsFilterList()
  );

  const getColumnFilter = (list?: any) => {
    if (useLocalStorage.getItem("filteredColumns") && !list) {
      let tempList = useLocaStorage.getItem("filteredColumns");

      setColumnFilteredList(tempList);
      setColumnsForList();
      columnDrawerClose();
    } else if (list) {
      list.unshift({ key: "checked", value: true });
      setColumnFilteredList(list);
      setColumnsForList();
      columnDrawerClose();
    }
  };

  const columnListFilter = () => {
    return (
      <div className={clsx(classes.list)} role="presentation">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.cancel}>
            <img
              src={filterColumn}
              style={{
                height: "20px",
                paddingRight: "8px",
              }}
              onClick={columnDrawerClose}
            />
            <div className={"filterHeading"}>
              {t("Jobfilter:Display Columns")}
            </div>
          </div>
          <div
            style={{ paddingRight: "22px", cursor: "pointer" }}
            onClick={drawerClose()}
          >
            <img src={crossIcon} height={"22px"} />
          </div>
        </div>
        <JobColumnFilter
          sendFilteredList={(updatedList: any) => getColumnFilter(updatedList)}
          list={columnFilteredList}
        ></JobColumnFilter>
      </div>
    );
  };
  const handleBulkReassignJobs = () => {
    reassignJobRef.current.handleOpen(rowsData);
  };
  const handleBulkDeleteJobs = () => {
    bulkDeleteRef.current.handleClickOpen();
  };

  const renderJobListActions = () => {
    return (
      <>
        {rowsData.selectedJobID.length === 0 ? (
          <div style={{ marginRight: "32px" }}>
            {/* Show Columns */}
            <div className={classes.filter} style={{ cursor: "pointer" }}>
              <Tooltip
                content={"Display Columns"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                hideOnClick={true}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div
                    className="filterButton"
                    style={{ transform: "none" }}
                    onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                  >
                    <img
                      style={{
                        height: "15px",
                      }}
                      src={ListColumnIcon}
                    ></img>
                  </div>
                  <Drawer
                    anchor={"right"}
                    open={columnFilterOpen}
                    onClose={drawerClose()}
                  >
                    {columnListFilter()}
                  </Drawer>
                </div>
              </Tooltip>
            </div>
            {/* Search Filter */}
            <div
              className={classes.filter}
              onClick={() => {
                setState(!showSearchFilter);
                setShowSearch(!showSearchFilter);
              }}
              style={{ cursor: "pointer" }}
            >
              <Tooltip
                content={"Search"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                hideOnClick={true}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div className="filterButton">
                    <img
                      style={{
                        height: "15px",
                      }}
                      src={SearchIcon}
                    ></img>
                  </div>
                  <Drawer
                    anchor={"right"}
                    open={showSearchFilter}
                    onClose={drawerClose()}
                  >
                    {list()}
                  </Drawer>
                  {searchBadgeCount ? (
                    <div className="badge">
                      <Badge
                        count={1}
                        style={{
                          backgroundColor: "var(--primary-theme)",
                          color: "white",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Tooltip>
            </div>
            {/* Filters */}
            <div className={classes.filter}>
              <Tooltip
                content={"Filters"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                hideOnClick={true}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div className="filterButton" onClick={toggleDrawer(state)}>
                    <img
                      style={{
                        height: "15px",
                      }}
                      src={FilterIcon}
                    ></img>
                  </div>
                  <Drawer anchor={"right"} open={state} onClose={drawerClose()}>
                    {list()}
                  </Drawer>
                  {BadgeCount ? (
                    <div className="badge">
                      <Badge
                        count={BadgeCount}
                        style={{
                          backgroundColor: "var(--primary-theme)",
                          color: "white",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div style={{ marginRight: "32px" }}>
            <div
              className={classes.filter}
              style={{ cursor: "pointer" }}
              onClick={() => handleBulkReassignJobs()}
            >
              <Tooltip
                content={"Update Jobs"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                hideOnClick={true}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div className="filterButton" style={{ transform: "none" }}>
                    <img
                      style={{
                        height: "17px",
                      }}
                      src={ReassignIcon}
                    ></img>
                  </div>
                </div>
              </Tooltip>
            </div>

            {/* { Export jobs} */}
            <div
              className={classes.filter}
              onClick={() => {
                exportJobAsPDF();
              }}
              style={{ cursor: "pointer" }}
            >
              <Tooltip
                content={"Export Jobs"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                hideOnClick={true}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div className="filterButton">
                    <img
                      style={{
                        height: "17px",
                      }}
                      src={Export}
                    ></img>
                  </div>
                </div>
              </Tooltip>
            </div>
            {/* { Delete jobs} */}
            <div
              className={classes.filter}
              onClick={() => {
                handleBulkDeleteJobs();
              }}
              style={{ cursor: "pointer" }}
            >
              <Tooltip
                content={"Delete Jobs"}
                direction={"top"}
                style={{
                  backgroundColor: "rgba(97, 97, 97, 0.92)",
                  marginBottom: "-8px",
                }}
                hideOnClick={true}
                wrapperStyle={{
                  width: "-webkit-fill-available",
                }}
                className={classes.tooltipTop}
              >
                <div className="jobList__notification">
                  <div className="filterButton">
                    <img
                      style={{
                        height: "17px",
                      }}
                      src={Delete}
                    ></img>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </>
    );
  };
  const checkAllSelected = (arr: any, newSelectedJobIds?: any) => {
    let flag = false;
    let selectedJobId: any = newSelectedJobIds
      ? newSelectedJobIds
      : rowsData.selectedJobID;
    for (let i = arr.length - 1; i >= 0; i--) {
      if (!selectedJobId.includes(arr[i].job_id)) {
        flag = false;
        break;
      } else {
        flag = true;
      }
    }

    return flag;
  };
  const checkIsJobStatusFilterAppliedOrNot = () => {
    for (let i = 0; i < extraParamsForJobListApiHit.length; i++) {
      if (extraParamsForJobListApiHit[i].name == "job_status") {
        return true;
      }
    }
    return false;
  };
  let colorToShow: any;
  const isRefrenceIdSame = (row: any) => {
    if (rowsData.data.length > 0) {
      let indexOfRow: number = rowsData.data.findIndex(
        (element: any) => element.job_id == row.job_id
      );
      colorToShow = colorArrayObject[indexOfRow];
      return colorToShow;
    } else {
      return false;
    }
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => isRefrenceIdSame(row),
      style: {
        backgroundColor: "#f0f0f0",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row: any) => !isRefrenceIdSame(row),
      style: {
        backgroundColor: "#FFFFFF",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const customStyles = {
    header: { style: { fontWeight: "bold" }, height: "80px", color: "red" },
  };

  return (
    <Fragment>
      <div className="jobList datatable">
        <div
          hidden={showTaskDetail}
          style={{
            position: "relative",
            width: "100%",
            height: "115px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              minWidth: "100%",
              backgroundColor: "white",
            }}
          >
            <div className="jobList__header">
              <PageHeading title={t("Jobslist:Job List")} />
            </div>
            <div className="jobList__header" style={{ width: "inherit" }}>
              <JobListCount
                search={search}
                isListEmpty={isListEmpty}
                statusCount={statusCount}
                getFilterData={getFilterData}
                isSearchEnbled={search ? true : false}
              />
              {renderJobListActions()}
            </div>
          </div>
        </div>
        <div hidden={showTaskDetail} className="jobList datatable">
          <DataTable
            refreshDataTable={countRefreshDataTable}
            defaultSortColumnName={sort.sortColumn}
            defaultSortColumnOrder={sort.sortDirection}
            isJobListByRefrenceId={true}
            extraParams={extraParamsForJobListApiHit}
            showErrorMsgFromAPI
            conditionalRowStyles={conditionalRowStyles}
            refreshDataTableOnly={refreshTableState}
            resetPage={resetPage}
            customStyles={customStyles}
            // columns={isDataFiltered ? filteredColumnsData : columns}
            columns={columns}
            endPoint="main/job/get"
            sendDataToParent={(data: any, page?: any) => {
              const isJobStatusFilterApplied = checkIsJobStatusFilterAppliedOrNot();
              setStatusCount({
                count: data.count,
                statusCount: data.statusCount,
                isJobStatusFilterApplied: isJobStatusFilterApplied,
              });
              let tempData = { ...rowsData };
              if (checkAllSelected(data.data)) {
                tempData.allSelected = true;
              } else {
                tempData.allSelected = false;
              }
              // console.log(
              //   Object.values(data.data[0]["jobs"]),
              //   "datatatatw231312"
              // );
              let tempJobListArray: any = [],
                tempRefrenceId: any = [];
              if (data.data.length > 0) {
                setRefrenceIdArray(data.data[0]["reference_id"]);

                for (let index = 0; index < data.data.length; index++) {
                  for (
                    let internalLoopIndex = 0;
                    internalLoopIndex < data.data[index]["jobs"].length;
                    internalLoopIndex++
                  ) {
                    tempJobListArray.push(
                      data.data[index]["jobs"][internalLoopIndex]
                    );
                    tempRefrenceId.push(data.data[index]["reference_id"]);
                  }
                }
                tempData.refrenceIdArray = tempRefrenceId;
                console.log([tempJobListArray, tempRefrenceId], "datatatat");
                tempData.data = tempJobListArray;
              }

              setRowsData({ ...tempData });
            }}
            fixedHeader={true}
            fixedHeaderScrollHeight={"59vh"}
            getSortData={getSortData}
          />

          {/* <AddResource ref={addResourceRef}></AddResource> */}
        </div>

        <Suspense fallback={""}>
          <CreateJob ref={createTaskRef}></CreateJob>
        </Suspense>
        <Suspense fallback={""}>
          <Import ref={openImportRef}></Import>
        </Suspense>
        <Suspense fallback={""}>
          <ExportJobs ref={openExportRef} />
        </Suspense>
        <Suspense fallback={""}>
          <ReassignJob ref={reassignJobRef}></ReassignJob>
        </Suspense>
        {/* <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <JobListFilter ref={jobFilter}></JobListFilter>
      </Suspense> */}
        <Suspense fallback={""}>
          <AlertDialog
            ref={deleteTaskRef}
            description={t(
              "Jobslist:Are you sure you want to delete this job?"
            )}
            title={t("Jobslist:Delete Job")}
            confirm={t("Common:Confirm")}
            discard={t("Common:Discard")}
            onConfirm={onConfirm}
            onDiscard={onDiscard}
          ></AlertDialog>
        </Suspense>
        <Suspense fallback={""}>
          <AlertDialog
            ref={bulkDeleteRef}
            description={t(
              rowsData.selectedJobID.length === 1
                ? `Jobslist:Are you sure you want to delete this ${rowsData.selectedJobID.length} jobs?`
                : `Jobslist:Are you sure you want to delete these ${rowsData.selectedJobID.length} jobs?`
            )}
            title={t("Jobslist:Delete Jobs")}
            confirm={t("Common:Confirm")}
            discard={t("Common:Discard")}
            onConfirm={onConfirm}
            onDiscard={onDiscard}
          ></AlertDialog>
        </Suspense>
        <Suspense fallback={""}>
          {showTaskDetail ? (
            <TaskDetail
              onCloseTaskDetail={() => {
                setShowTaskDetail(false);
                setTaskIdForTaskDetail(null);
              }}
              taskId={taskIdForTaskDetail}
              tagsBarcodeData={tagsBarcodeForTaskDetail}
            ></TaskDetail>
          ) : null}
        </Suspense>
      </div>
    </Fragment>
  );
};

export default JobsListByRefrenceId;
